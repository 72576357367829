import * as React from "react";

import "./UnsubscribeForm.css";

interface DetailsProps {
  details?: {
    key?: string;
    CustomerPhoneNumber?: string;
    CustomerName?: string;
    SubscriberPhoneNumber?: string;
  };
}

interface UnsubscribeFormProps extends DetailsProps {
  unsubscribeHandler: Function;
}

export const UnsubscribeForm = ({
  details,
  unsubscribeHandler,
}: UnsubscribeFormProps) => {
  return (
    <>
      <div>
        <h2 className="title">Unsubscribe from SMS</h2>
        <p className="form--description">
          To stop receiving SMS
          {details?.CustomerName
            ? ` from ${details?.CustomerName}`
            : details?.CustomerPhoneNumber
            ? ` from ${details?.CustomerPhoneNumber}`
            : ""}
          , click unsubscribe below.
        </p>
      </div>

      <button onClick={() => unsubscribeHandler(details?.key)} type="button">
        Unsubscribe
      </button>
    </>
  );
};
