import { datadogRum } from "@datadog/browser-rum";

//https://docs.datadoghq.com/real_user_monitoring/browser/
datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APP_ID ?? "",
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? "",
  site: "datadoghq.com",
  service: process.env.REACT_APP_SERVICE ?? "",
  env: process.env.REACT_APP_ENV ?? "",
  version: process.env.REACT_APP_VERSION ?? "",
  sampleRate: 100,
  premiumSampleRate: 5,
  trackInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();