import * as React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { Failure, Loading, Success, UnsubscribeForm } from "../components";

function Unsubscribe() {
  const params = useParams();
  const { key } = params;

  const [isLoaded, setisLoaded] = React.useState(true);
  const [status, setStatus] = React.useState("READY");
  const [details, setDetails] = React.useState({});

  React.useEffect(() => {
    if (key) {
      setisLoaded(false);
      axios
        .get(
          `https://opt-out-bff.api${
            process.env.REACT_APP_ENV !== "prd"
              ? `.${process.env.REACT_APP_ENV}`
              : ""
          }.eng.cmgroup.com/${key}`
        )
        .then(
          (response) => {
            setisLoaded(true);
            setDetails({
              ...response.data,
              key,
            });
          },
          (error) => {
            setisLoaded(true);
            setStatus("ERROR");
          }
        );
    }
  }, [key]);

  function unsubscribeHandler(key: string) {
    setisLoaded(false);
    axios
      .post(
        `https://opt-out-bff.api${
          process.env.REACT_APP_ENV !== "prd"
            ? `.${process.env.REACT_APP_ENV}`
            : ""
        }.eng.cmgroup.com/${key}`
      )
      .then(
        (response) => {
          setisLoaded(true);
          setStatus("SUCCESS");
        },
        (error) => {
          setisLoaded(true);
          setStatus("ERROR");
        }
      );
  }

  return (
    <main className="main">
      {!isLoaded && <Loading />}
      {isLoaded && status === "SUCCESS" && <Success />}
      {isLoaded && status === "ERROR" && <Failure details={details} />}
      {isLoaded && status !== "SUCCESS" && (
        <UnsubscribeForm
          details={details}
          unsubscribeHandler={unsubscribeHandler}
        />
      )}
    </main>
  );
}

export default Unsubscribe;
