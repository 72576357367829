import * as React from "react";

import "./Failure.css";
import AlertIcon from "./AlertIcon.svg";

interface FailureProps {
  details?: {
    key?: string;
    CustomerPhoneNumber?: string;
    CustomerName?: string;
    SubscriberPhoneNumber?: string;
  };
}

export function Failure({ details }: FailureProps) {
  return (
    <div className="failure">
      <img alt="Error" src={AlertIcon} />
      <p className="failure--description">
        We couldn&apos;t unsubscribe you
        {details?.CustomerName
          ? ` from ${details?.CustomerName}`
          : details?.CustomerPhoneNumber
          ? ` from ${details?.CustomerPhoneNumber}`
          : ""}
        . Please try again.
      </p>
    </div>
  );
}
