import * as React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Unsubscribe from "./pages/Unsubscribe";

function App() {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path=":key" element={<Unsubscribe />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
