import * as React from "react";

import "./Success.css";

export const Success = () => {
  return (
    <>
      <div>
        <h2 className="title">You&apos;ve unsubscribed from SMS.</h2>
        <p className="success--description">
          You&apos;ve successfully unsubscribed from receiving marketing
          messages.
        </p>
      </div>
    </>
  );
};
