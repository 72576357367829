import * as React from "react";

import "./Loading.css";

export function Loading() {
  return (
    <div className="loader--container">
      <span className="loader--spinner"></span>
    </div>
  );
}
